import { h, Component } from 'preact';
import styles from './styles.scss';
import { Link } from '../../shared/link';
import { TopicFlyout } from '../../shared/topic-flyout';
import { Flyout, FlyoutToggle } from '../../shared/flyout';
import { breakpointLarge } from '../../sass/variables.scss';
import { UseCaretIcon } from '../../shared/svg/icons/caret';
import { withTheme } from '../../shared/withTheme';
import { I18n, Config } from '../../shared/context';
import { Arcade } from '../../shared/svg/logos/arcade';
import parse from 'html-react-parser';

function enableFlyout() {
  const { matches } = window.matchMedia(`(min-width:${breakpointLarge})`);
  return !!matches;
}

const TopicLink = withTheme(({ theme, flyout = null, ...props }) => {
  if (flyout) {
    return (
      <Config.Consumer>
        {config => (
          <Flyout width={420} enabled={enableFlyout} location={'topicBar'} label={props.label} ga={config.ga}>
            <FlyoutToggle>
              <Link location={'topicBar'} className={`${styles.link} ${theme.link}`} {...props}>
                {props.emoji} {props.children}
                <UseCaretIcon className={styles.downCaret} aria-hidden={true} />
              </Link>
            </FlyoutToggle>
            <TopicFlyout location={props.label} {...flyout} />
          </Flyout>
        )}
      </Config.Consumer>
    );
  }
  return <Link location={'topicBar'} className={`${styles.link} ${theme.link} ${props.mobileOnly ? styles.mdHide : ''}`} {...props} />;
});

class TopicNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedItems: [...props.topics],
      dailyFact: null
    };
  }

  updateDailyFact = (eventMessage) => {
    if (
      eventMessage?.data?.type === 'fact-of-the-day' &&
      eventMessage?.data?.message?.text
    ) {
      this.setState({ ...this.state, dailyFact: eventMessage.data.message });
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.updateDailyFact);
    window.parent.postMessage(
      { type: 'topic-nav-loaded' },
      '*'
    );
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.updateDailyFact);
  }

  getLogo(name) {
    if (name === 'Arcade') {
      return <Arcade width={13} height={15} />;
    }

    return null;
  }

  render({ theme }, state) {
    /* eslint-disable new-cap */
    return <I18n.Consumer>
      { i18n =>
        <div className={`${theme.subNavContainer} ${styles.subNavContainer}`} >
          <nav className={`${theme.topicNavWrapper} ${styles.wrapper}`} aria-label={i18n.hot_topics}>
            <ul className={`${theme.topicNav} ${styles.topicNav}`} ref={el => this.element = el}>
              {
                state.displayedItems.map(({ url, name, logo, label, ...data }) => (
                  <li className={`${styles.topicNavItem} ${logo ? styles.topicNavItemWithLogo : ''}`}>
                    <TopicLink href={url} label={label || name} {...data}>{logo && this.getLogo(logo)}{name}</TopicLink>
                  </li>
                ))
              }
            </ul>
            {!!state.dailyFact?.text?.length && !state.dailyFact?.url?.length && <span className={styles.dailyFact} id='daily-fact' dangerouslySetInnerHTML={{ __html: state.dailyFact.text }} />}
            {!!state.dailyFact?.text?.length && !!state.dailyFact?.url?.length && (
              <Link
                className={styles.dailyFactLink}
                id='daily-fact'
                href={state.dailyFact.url}
                location={'dailyFact'}
                label={'daily_fact'}
                target={state.dailyFact?.forceNewBrowserTab ? '_blank' : '_self'}
                trackingData={{
                  position_in_unit: state?.displayedItems.length
                }}>
                {parse(state.dailyFact.text)}
              </Link>
            )}
          </nav>
        </div>
      }
    </I18n.Consumer>;
    /* eslint-enable new-cap */
  }
}

export const ThemedTopicNav = withTheme(TopicNav);
