import cookies from '@buzzfeed/bf-utils/lib/cookies';
import { h, Component, createRef } from 'preact';
import styles from './styles.scss';
import { CircleCheckIcon, CircleExclamationIcon, XIcon } from '@buzzfeed/react-components/dist/module/lib/components/Icons';
import mock from './mock';
import Toast from './Toast';
import toastStyles from './toast.scss';
import { Tracking } from '../context';


const UPSTREAM = '/newsletters/api/subhub/v1';
const DEFAULT_HEADERS = {
  'Content-type': 'application/json',
  'X-XSRF': cookies.get('_xsrf', ''),
};
const TOAST_HIDE_DELAY = 6000; // 6 seconds
const NEWSLETTER_CATEGORIES = ['trending', 'in_case_you_missed_it', 'courses'];

export class NewsletterSignup extends Component {

  static contextType = Tracking;

  constructor() {
    super();
    this.submitButton = createRef();
    this.state = {
      scroll: false,
      order: ['buzzfeed_email_daily', 'buzzfeed_email_shopping', 'buzzfeed_email_quizzes', 'buzzfeed_email_tasty'],
      checked: [],
      options: [],
      showToast: false,
      error: false,
      trackingData: {
        location: 'newsletterSignupModal',
        unit_type: 'nav',
        unit_name: 'main',
        item_type: 'submission',
        item_name: 'email',
        subunit_type: 'component',
        subunit_name: 'newsletter_subscription',
        target_content_type: 'submission',
        target_content_id: 'newsletter_subscription',
      },
      errorToast: {
        message: 'Oops, something went wrong. Please try again.',
        icon: <CircleExclamationIcon className={`${toastStyles.toastIcon} ${toastStyles.toastErrorIcon}`}/>,
        className: toastStyles.toastError,
      },
      successToast: {
        message: 'Your subscriptions have been updated.',
        icon: <CircleCheckIcon className={`${toastStyles.toastIcon} ${toastStyles.toastSuccessIcon}`}/>,
      },
    };
    this.handleHide = this.handleHide.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleCloseToast = this.handleCloseToast.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSeeAllNewsletters = this.handleSeeAllNewsletters.bind(this);
  }

  setOptions(items) {
    const orderByConfig = (array, config) => {
      const orderMap = new Map(config.map((name, index) => [name, index]));
      return array.sort((a, b) => {
        const aIndex = orderMap.get(a.name);
        const bIndex = orderMap.get(b.name);
        // If both objects have names in the config, sort by order
        if (aIndex !== undefined && bIndex !== undefined) {
          return aIndex - bIndex;
        }
        // If only one object has a name in the config, put it first
        return (aIndex !== undefined) ? -1 : (bIndex !== undefined) ? 1 : 0;
      });
    };
    const filterCategory = ({ category }) => NEWSLETTER_CATEGORIES.includes(category);
    const mapChecked = (o) => {
      return { ...o, checked: (this.state.checked.includes(o.name)) ? { checked: true, 'aria-checked': true } : { disabled: false } };
    };
    const options = orderByConfig(items.filter(filterCategory), this.state.order).map(mapChecked);
    this.setState({ options });
  }

  handleError() {
    this.setState({ showToast: true, error: true });
    setTimeout(this.props.onHide, TOAST_HIDE_DELAY);
    this.props.track.contentAction({
      ...this.state.trackingData,
      item_type: 'submission',
      item_name: 'email',
      action_type: 'submit',
      action_value: `buzzfeed_email_failed`,
    });
  }

  handleSuccess() {
    this.setState({ showToast: true });
    setTimeout(this.props.onHide, TOAST_HIDE_DELAY);
  }

  handleFetch() {
    const isDev = window.location.hostname.includes('bf-header-ui.dev.buzzfeed.io');
    if (isDev) {
      this.setOptions(mock.newsletters);
      return null;
    }
    const edition = this.state.edition ? `&edition=${this.state.edition}` : '';
    fetch(`${UPSTREAM}/newsletters?subhub=true&brand=${this.state.brand}${edition}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.hasOwnProperty('newsletters') && response.newsletters.length) {
          this.setOptions(response.newsletters);
        } else {
          this.props.onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        this.handleError();
      });
    return null;
  }

  handleSubmit(evt) {
    if (typeof evt?.preventDefault === 'function') {
      evt.preventDefault();
    }
    const inputs = Array.from(document.getElementById('newsletter').querySelectorAll('input[type=checkbox]:checked'));
    const subscriptions = inputs.map(checkbox => checkbox.value);
    const isDev = false; //window.location.hostname.includes('bf-header-ui.dev.buzzfeed.io');
    if (isDev) {
      this.handleError();
      return;
    }
    const payload = {
      subscriptions,
      brand: this.state.brand,
      source: this.state.source,
    };
    fetch(`${UPSTREAM}/users/subscribe`, {
      headers: DEFAULT_HEADERS,
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if ([200, 201, 202, 203].includes(response.status)) {
          this.handleSuccess();
          this.props.track.contentAction({
            ...this.state.trackingData,
            item_type: 'submission',
            item_name: 'email',
            action_type: 'submit',
            action_value: `${subscriptions.join(',')}`,
          });
        } else {
          this.handleError();
        }
      })
      .catch((error) => {
        console.error(error);
        this.handleError();
      });
  }

  handleSeeAllNewsletters() {
    this.setState({ limit: 100, toggleSeeAll: true });
    this.props.track.contentAction({
      ...this.state.trackingData,
      item_type: 'button',
      item_name: 'see_all_newsletters',
      action_type: 'maximize',
      action_value: 'see_all_newsletters'
    });
  }

  handleScroll() {
    if (!this.state.scroll) {
      this.setState({ scroll: true });
    }
  }

  onKeyDown(evt) {
    // Escape key
    if (evt.keyCode === 27) {
      this.props.onHide(evt);
    }
  }

  onClickOutside(evt) {
    evt.preventDefault();
    // Click outside of content
    if (evt.target.id === 'newsletter') {
      this.props.onHide(evt);
    }
  }

  handleCloseToast() {
    this.setState({ showToast: false });
  }

  handleHide(e) {
    e.preventDefault();
    let itemName = 'close_modal';
    if (e.target.className.toString().includes('newsletterLink')) {
      itemName = 'maybe_later';
    }
    this.props.onHide();
    this.props.track.contentAction({
      ...this.state.trackingData,
      item_name: itemName,
      item_type: 'button',
      action_value: 'newsletter_subscription',
      action_type: 'close',
    });
  }

  detectBrand(url) {
    let domain = 'buzzfeed';
    if (url.includes('huffpost') || url.includes('huffingtonpost')) {
      domain = 'huffpost';
    }
    if (url.includes('complex')) {
      domain = 'complex';
    }
    if (url.includes('buzzfeed.com')) {
      domain = 'buzzfeed';
    }
    return domain;
  }

  componentWillUnmount() {
    if (typeof this.detachImpressionHandler === 'function') {
      try {
        this.detachImpressionHandler();
      } catch (error) {
        console.error('Unable to call detachImpressionHandler: ', error);
      }
    }
    window.removeEventListener('keydown', this.onKeyDown, true);
  }
  componentDidMount() {
    const detachClientImpressionHandler = this.context.createImpressionHandler(this.submitButton.current, { ...this.state.trackingData });
    this.detachImpressionHandler = () => detachClientImpressionHandler();
    window.addEventListener('keydown', this.onKeyDown, true);
    const isMobile = window.matchMedia('(max-width: 500px)').matches;
    const device = (isMobile) ? 'mobile' : 'desktop';
    const brand = this.detectBrand(window.location.hostname);
    const language = navigator.language.toLocaleLowerCase().split('-').slice(0)[0];
    const edition = `${language}-${this.props.edition}`;
    this.setState({
      edition,
      brand,
      source: `${brand}-${device}-nav`,
      checked: (this.props.edition === 'us') ? ['buzzfeed_email_daily', 'buzzfeed_email_shopping'] : [],
      limit: (isMobile) ? 100 : 4,
      toggleSeeAll: false,
    }, () => {
      this.handleFetch();
    });
    const newsletterItemContainer = document.getElementById('newsletterItemContainer');
    if (newsletterItemContainer) {
      newsletterItemContainer.focus();
    }
  }

  render() {
    const filterLimit = (value, index) => {
      return index < this.state.limit;
    };

    if (this.state.showToast) {
      return (
        <Toast {...((this.state.error) ? this.state.errorToast : this.state.successToast)} showToast={true} onClose={() => {}}/>
      );
    } else {
      return (
        <div id="newsletter" onMouseDown={this.onClickOutside} role="button" tabIndex={0} className={styles.newsletter}>
          <form
            className={`${styles.newsletterContent} ${(this.state.toggleSeeAll) ? styles.newsletterContent__open : ''}`}>
            <button type="button"
              onKeyDown={this.handleHide} onClick={this.handleHide}
              aria-label={'Close'}
              className={styles.newsletterClose}
            >
              <XIcon className={styles.newsletterCloseIcon}/>
            </button>
            <fieldset className={styles.newsletterFieldset}>
              <legend className={styles.newsletterTitle}>Sign up for newsletters</legend>
              <div className={styles.newsletterSubhead}>
                Keep up with trending buzz you’ll want to share with your friends.
              </div>
              <div id="newsletterItemContainer" onScroll={this.handleScroll} onTouchStart={this.handleScroll}
                className={`${styles.newsletterItemContainer} 
                  ${(this.state.scroll) ? styles.newsletterItemContainer__scroll : ''}
                  ${(this.state.toggleSeeAll) ? styles.newsletterItemContainer__open : ''}`}>
                {this.state.options.filter(filterLimit).map((option) => (
                  <div className={styles.newsletterItem}>
                    <img alt={option.title} src={option.image}/><br/>
                    <div className={styles.newsletterItemWrapper}>
                      <div className={styles.newsletterCheckboxContainer}>
                        <input id={option.name} value={option.name} tabindex={0} area-label={option.title} type="checkbox"
                          className={styles.newsletterCheckbox} {...option.checked}/>
                        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label for={option.name} className={styles.newsletterCheckboxCheckmark}/>
                      </div>
                      <div className={styles.newsletterItemDetails}>
                        <div className={styles.newsletterItemTitle}>{option.title}</div>
                        <div className={styles.newsletterItemFrequency}>{option.frequency}</div>
                        <div className={styles.newsletterItemText}>{option.description}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.newsletterFooter}>
                {(this.state.toggleSeeAll === false) &&
                <button type="button"
                  onKeyDown={this.handleSeeAllNewsletters}
                  onClick={this.handleSeeAllNewsletters}
                  aria-label={'See all newsletters'}
                  className={`${styles.newsletterLink} ${styles.newsletterDesktopOnly}`}
                >See all newsletters</button>
                }
                <div className={styles.newsletterLegal}>
                  By subscribing, you're agreeing to let us send you customized messages regarding our content.
                  You are also agreeing to our Terms of Service and Privacy Policy.
                </div>
                <button type="button" ref={this.submitButton}
                  onKeyDown={this.handleSubmit} onClick={this.handleSubmit}
                  aria-label={'Subscribe to newsletters'}
                  className={styles.newsletterSubmit}
                >Subscribe to newsletters</button>
                <button type="button"
                  onKeyDown={this.handleHide} onClick={this.handleHide}
                  aria-label={'Maybe later'}
                  className={`${styles.newsletterLink} ${styles.newsletterAlignCenter}`}
                >Maybe later</button>
              </div>
            </fieldset>
          </form>
        </div>
      );
    }
  }
}
