import { h } from 'preact';
import { Theme } from './context';
import purpleTheme from '../sass/themes/purple.scss';
import lightTheme from '../sass/themes/light.scss';
import lightNewsTheme from '../sass/themes/light_news.scss';
import lightNewsFeedTheme from '../sass/themes/light_news_feed.scss';
import greenTheme from '../sass/themes/green.scss';
import transparentTheme from '../sass/themes/transparent.scss';
import lightFWSNTheme from '../sass/themes/light_fwsn.scss';
import complexDark from '../sass/themes/complex_dark.scss';
import complexLight from '../sass/themes/complex_light.scss';

const themeClassNamesMap = {
  complex_dark: complexDark,
  complex_light: complexLight,
  green: greenTheme,
  light: lightTheme,
  light_fwsn: lightFWSNTheme,
  light_news: lightNewsTheme,
  light_news_feed: lightNewsFeedTheme,
  purple: purpleTheme,
  transparent: transparentTheme,
};

export const withTheme = (Component) => (props) =>
  (
    <Theme.Consumer>
      {(theme) => <Component theme={themeClassNamesMap[theme]} themeName={theme} {...props} />}
    </Theme.Consumer>
  );
